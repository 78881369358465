<template>
	<div class="car-list">
		<transition name="fade">
			<div class="preloader" v-if="!loaded">
				<div class="spinner"></div>
			</div>
		</transition>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="3" lg="2" xl="2">
				<filter-side v-if="!$vuetify.breakpoint.smAndDown && loaded" />
			</v-col>
			<v-col cols="12" sm="12" md="9" lg="10" xl="10" style="position: relative">
				<car-list ref="carList" />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { FETCH_CAR_LIST, FETCH_PRESET, SET_REQUEST_READY_STATUS } from '@/store/modules/listing.module';
import { mapActions, mapGetters } from 'vuex';
import CarList from './components/carList.vue';
import filterSide from './filter/filterSide.vue';

export default {
	components: {
		filterSide,
		CarList,
	},
	name: 'Cars',
	data() {
		return {
			reqCount: 0,
			loaded: false,
			cars: {
				carList: {
					data: [],
					count: 0,
				},
			},
			urlFilterInit: false,
		};
	},
	computed: {
		...mapGetters(['filterPayload', 'filterPreset', 'readyForRequest', 'isFetching']),
		isUrlFilter() {
			return this.urlFilterInit;
		},
		isMobileRequest() {
			return this.$vuetify.breakpoint.smAndDown && !this.isUrlFilter;
		},
	},
	mounted() {
		this.fetchPreset().then(() => {
			this.initializeFiltersFromURL().then(() => {
				this.fetch(true);
			});
		});
	},
	methods: {
		...mapActions({
			fetchPreset: FETCH_PRESET,
			getCars: FETCH_CAR_LIST,
			setRequestReadyStatus: SET_REQUEST_READY_STATUS,
		}),
		initializeFiltersFromURL() {
			return new Promise((resolve) => {
				const brandSlugs = this.$route.params.brands ? this.$route.params.brands.split('-') : [];
				const modelSlugs = this.$route.params.models ? this.$route.params.models.split('-') : [];

				if (brandSlugs.length > 0) {
					const selectedBrandIds = brandSlugs
						.map((brandSlug) => {
							const brand = this.filterPreset.brands.find((b) => this.$toSlug(b.value, '_') === brandSlug);
							return brand ? brand.id : null;
						})
						.filter((id) => id !== null);

					this.filterPayload.brands = selectedBrandIds;
					this.urlFilterInit = true;
				}

				if (modelSlugs.length > 0) {
					const selectedModelIds = [];

					this.filterPayload.brands.forEach((brandId) => {
						const brand = this.filterPreset.brands.find((b) => b.id === brandId);
						if (brand) {
							const models = brand.models
								.filter((model) => modelSlugs.includes(this.$toSlug(model.value, '_')))
								.map((model) => model.id);
							selectedModelIds.push(...models);
						}
					});

					this.filterPayload.models = selectedModelIds;
					this.urlFilterInit = true;
				}
				resolve();
			});
		},
		async getCarsByFilter(init) {
			return new Promise((resolve) => {
				if (this.readyForRequest && this.reqCount === 0) {
					this.reqCount += 1;
					this.getCars({ init, isMobileRequest: this.isMobileRequest }).finally(() => {
						resolve();

						this.loaded = true;
						this.urlFilterInit = false;
					});
				}
			});
		},
		fetch(init) {
			this.$nextTick(async () => {
				await this.getCarsByFilter(init);
				this.reqCount = 0;
				this.setRequestReadyStatus(true);
			});
		},
	},
	watch: {
		filterPayload: {
			handler() {
				this.fetch(false);
			},
			deep: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	z-index: 9999;
	opacity: 1;
	transition: opacity 1s;

	&.fade-out {
		opacity: 0;
	}

	.spinner {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 60px;
		height: 60px;
		margin: -30px 0 0 -30px;
		border: 5px solid var(--v-template-color-base);
		border-top-color: var(--v-template-color-darken3);
		border-radius: 100%;
		animation: spin 1s ease-in-out infinite;
	}

	@keyframes spin {
		to {
			transform: rotate(360deg);
		}
	}
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}
</style>
