<template>
	<div class="filter-expansion fill-height" :style="style">
		<div class="filter-side" :class="$vuetify.breakpoint.smAndDown ? 'mobile' : 'overflow-y-auto'" v-if="filterPreset">
			<v-container fluid class="grey lighten-5 clear-filter">
				<div class="d-flex justify-space-around align-center px-1">
					<v-list-item-title class="font-weight-bold grey--text">Arama Kriterleri</v-list-item-title>
					<v-spacer></v-spacer>
					<v-btn class="text-capitalize font-weight-bold" color="grey" text small @click="resetFilter">
						<v-icon small>fas fa-times mr-1</v-icon>
						Temizle
					</v-btn>
				</div>
			</v-container>
			<div class="pb-10 white">
				<v-list flat tile subheader light class="pa-0">
					<template class="py-0 font-weight-medium" v-if="anyCountGreaterThanZero">
						<v-list-item v-for="(oppor, index) in opportunits" :key="`oppor-${index}`">
							<v-list-item-content class="pa-0">
								<v-checkbox
									class="pa-0 ma-0"
									color="template-color darken-2"
									hide-details="auto"
									label="Fırsat Araçlar"
									:ripple="false"
									:value="oppor.id"
									v-model="filterOptions.opportunity"
									@change="resetPageNumber"
								></v-checkbox>
							</v-list-item-content>
							<v-list-item-action-text>
								<v-badge
									left
									inline
									offset-x="0"
									class="font-weight-medium"
									color="grey lighten-3 secondary--text"
									:content="oppor.count"
								>
								</v-badge>
							</v-list-item-action-text>
						</v-list-item>
					</template>
					<v-list-group :value="false" no-action class="font-weight-bold" :ripple="false" color="grey darken-4">
						<template v-slot:activator>
							<v-list-item-title> Araç Tipi </v-list-item-title>
						</template>
						<v-list class="py-0 font-weight-medium">
							<v-list-item v-for="(category, index) in categories" :key="`category-${index}`">
								<v-list-item-content class="pa-0">
									<v-checkbox
										class="pa-0 ma-0"
										color="template-color darken-2"
										hide-details="auto"
										:ripple="false"
										:label="category.value"
										:value="category.id"
										:multiple="true"
										v-model="filterOptions.categories"
										@change="resetPageNumber"
									>
									</v-checkbox>
								</v-list-item-content>
								<v-list-item-action-text v-if="showFilterCount">
									<v-badge
										left
										inline
										offset-x="0"
										class="font-weight-medium"
										color="grey lighten-3 secondary--text"
										:content="category.count"
									>
									</v-badge>
								</v-list-item-action-text>
							</v-list-item>
						</v-list>
					</v-list-group>
				</v-list>

				<v-list flat tile subheader light class="pa-0">
					<v-list-group :value="true" no-action class="font-weight-bold" :ripple="false" color="grey darken-4">
						<template v-slot:activator>
							<v-list-item-title> Marka & Model </v-list-item-title>
						</template>
						<v-list class="pa-0" flat tile subheader expand light>
							<v-list-group
								no-action
								class="font-weight-medium pa-0 ma-0 brand-item"
								v-for="(brand, brandIndex) in filterPreset.brands"
								:ripple="false"
								:key="`brand-${brandIndex}`"
								:value="isOpen(brand)"
								color="grey darken-2"
							>
								<template v-slot:activator>
									<v-list-item class="pl-0">
										<v-list-item-content class="pa-0">
											<v-checkbox
												@click.stop
												@change="toggleBrand(brand)"
												class="pa-0 ma-0"
												color="template-color darken-2"
												hide-details="auto"
												:ripple="false"
												:label="brand.value"
												:value="brand.id"
												:multiple="true"
												v-model="filterOptions.brands"
											></v-checkbox>
										</v-list-item-content>
									</v-list-item>
								</template>

								<v-list-item class="pl-8" v-for="(model, modelIndex) in brand.models" :key="`model-${modelIndex}`">
									<v-list-item-content class="pa-0">
										<v-checkbox
											class="pa-0 ma-0"
											color="template-color darken-2"
											hide-details="auto"
											:ripple="false"
											:label="model.value"
											:value="model.id"
											:multiple="true"
											v-model="filterOptions.models"
											@change="toggleModel(brand)"
										></v-checkbox>
									</v-list-item-content>
									<v-list-item-action-text v-if="showFilterCount">
										<v-badge
											left
											inline
											offset-x="0"
											class="font-weight-medium"
											color="grey lighten-3 secondary--text"
											:content="model.count"
										>
										</v-badge>
									</v-list-item-action-text>
								</v-list-item>
							</v-list-group>
						</v-list>
					</v-list-group>
				</v-list>

				<v-list flat tile subheader light class="pa-0">
					<v-list-group :value="false" no-action class="font-weight-bold" :ripple="false" color="grey darken-4">
						<template v-slot:activator>
							<v-list-item-title> Versiyon </v-list-item-title>
						</template>
						<v-list class="py-0 font-weight-medium">
							<v-list-item v-for="(version, index) in versions" :key="`version-${index}`">
								<v-list-item-content class="pa-0">
									<v-checkbox
										class="pa-0 ma-0"
										color="template-color darken-2"
										hide-details="auto"
										:ripple="false"
										:label="version.value"
										:value="version.id"
										:multiple="true"
										v-model="filterOptions.versions"
										@change="resetPageNumber"
									></v-checkbox>
								</v-list-item-content>
								<v-list-item-action-text v-if="showFilterCount">
									<v-badge
										left
										inline
										offset-x="0"
										class="font-weight-medium"
										color="grey lighten-3 secondary--text"
										:content="version.count"
									>
									</v-badge>
								</v-list-item-action-text>
							</v-list-item>
						</v-list>
					</v-list-group>
				</v-list>

				<v-list flat tile subheader light class="pa-0">
					<v-list-group :value="true" no-action class="font-weight-bold" :ripple="false" color="grey darken-4">
						<template v-slot:activator>
							<v-list-item-title> Yıl </v-list-item-title>
						</template>
						<v-list class="py-0 font-weight-medium">
							<v-list-item class="px-5">
								<v-list-item-content>
									<histogram-slider
										ref="yearHistogram"
										color="template-color"
										:series="years"
										:bar-container-height="50"
										v-model="yearRange"
									></histogram-slider>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-list-group>
				</v-list>

				<v-list flat tile subheader light class="pa-0">
					<v-list-group :value="true" no-action class="font-weight-bold" :ripple="false" color="grey darken-4">
						<template v-slot:activator>
							<v-list-item-title> Kilometre </v-list-item-title>
						</template>
						<v-list class="py-0 font-weight-medium">
							<v-list-item class="px-5">
								<v-list-item-content>
									<histogram-slider
										ref="kmHistogram"
										color="template-color"
										:series="kilometers"
										:bar-container-height="50"
										v-model="kmRange"
									></histogram-slider>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-list-group>
				</v-list>

				<v-list flat tile subheader light class="pa-0">
					<v-list-group :value="true" no-action class="font-weight-bold" :ripple="false" color="grey darken-4">
						<template v-slot:activator>
							<v-list-item-title> Fiyat </v-list-item-title>
						</template>
						<v-list class="py-0 font-weight-medium">
							<v-list-item class="px-5">
								<v-list-item-content>
									<histogram-slider
										ref="priceHistogram"
										color="template-color"
										:series="prices"
										:bar-container-height="50"
										v-model="priceRange"
									></histogram-slider>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-list-group>
				</v-list>

				<v-list flat tile subheader light class="pa-0">
					<v-list-group :value="true" no-action class="font-weight-bold" :ripple="false" color="grey darken-4">
						<template v-slot:activator>
							<v-list-item-title> Vites Tipi </v-list-item-title>
						</template>
						<v-list class="py-0 font-weight-medium">
							<v-list-item v-for="(transmissionType, index) in transmissionTypes" :key="`transmissionType-${index}`">
								<v-list-item-content class="pa-0">
									<v-checkbox
										class="pa-0 ma-0"
										color="template-color darken-2"
										hide-details="auto"
										:ripple="false"
										:label="transmissionType.value"
										:value="transmissionType.id"
										:multiple="true"
										v-model="filterOptions.transmissionTypes"
										@change="resetPageNumber"
									></v-checkbox>
								</v-list-item-content>
								<v-list-item-action-text v-if="showFilterCount">
									<v-badge
										left
										inline
										offset-x="0"
										class="font-weight-medium"
										color="grey lighten-3 secondary--text"
										:content="transmissionType.count"
									>
									</v-badge>
								</v-list-item-action-text>
							</v-list-item>
						</v-list>
					</v-list-group>
				</v-list>

				<v-list flat tile subheader light class="pa-0">
					<v-list-group :value="true" no-action class="font-weight-bold" :ripple="false" color="grey darken-4">
						<template v-slot:activator>
							<v-list-item-title> Yakıt </v-list-item-title>
						</template>
						<v-list class="py-0 font-weight-medium">
							<v-list-item v-for="(fuelType, index) in fuelTypes" :key="`fuelType-${index}`">
								<v-list-item-content class="pa-0">
									<v-checkbox
										class="pa-0 ma-0"
										color="template-color darken-2"
										hide-details="auto"
										:ripple="false"
										:label="fuelType.value"
										:value="fuelType.id"
										:multiple="true"
										v-model="filterOptions.fuelTypes"
										@change="resetPageNumber"
									></v-checkbox>
								</v-list-item-content>
								<v-list-item-action-text v-if="showFilterCount">
									<v-badge
										left
										inline
										offset-x="0"
										class="font-weight-medium"
										color="grey lighten-3 secondary--text"
										:content="fuelType.count"
									>
									</v-badge>
								</v-list-item-action-text>
							</v-list-item>
						</v-list>
					</v-list-group>
				</v-list>

				<v-list flat tile subheader light class="pa-0">
					<v-list-group :value="false" no-action class="font-weight-bold" :ripple="false" color="grey darken-4">
						<template v-slot:activator>
							<v-list-item-title> Kasa Tipi </v-list-item-title>
						</template>

						<v-list class="py-0 font-weight-medium">
							<v-list-item v-for="(bodyType, index) in bodyTypes" :key="`bodyType-${index}`">
								<v-list-item-content class="pa-0">
									<v-checkbox
										class="pa-0 ma-0"
										color="template-color darken-2"
										hide-details="auto"
										:ripple="false"
										:label="bodyType.value"
										:value="bodyType.id"
										:multiple="true"
										v-model="filterOptions.bodyTypes"
										@change="resetPageNumber"
									></v-checkbox>
								</v-list-item-content>
								<v-list-item-action-text v-if="showFilterCount">
									<v-badge
										left
										inline
										offset-x="0"
										class="font-weight-medium"
										color="grey lighten-3 secondary--text"
										:content="bodyType.count"
									>
									</v-badge>
								</v-list-item-action-text>
							</v-list-item>
						</v-list>
					</v-list-group>
				</v-list>

				<v-list flat tile subheader light class="pa-0">
					<v-list-group :value="false" no-action class="font-weight-bold" :ripple="false" color="grey darken-4">
						<template v-slot:activator>
							<v-list-item-title> Çekiş Türü </v-list-item-title>
						</template>
						<v-list class="py-0 font-weight-medium">
							<v-list-item v-for="(tractionType, index) in tractionTypes" :key="`tractionType-${index}`">
								<v-list-item-content class="pa-0">
									<v-checkbox
										class="pa-0 ma-0"
										color="template-color darken-2"
										hide-details="auto"
										:ripple="false"
										:label="tractionType.value"
										:value="tractionType.id"
										:multiple="true"
										v-model="filterOptions.tractionTypes"
										@change="resetPageNumber"
									></v-checkbox>
								</v-list-item-content>
								<v-list-item-action-text v-if="showFilterCount">
									<v-badge
										left
										inline
										offset-x="0"
										class="font-weight-medium"
										color="grey lighten-3 secondary--text"
										:content="tractionType.count"
									>
									</v-badge>
								</v-list-item-action-text>
							</v-list-item>
						</v-list>
					</v-list-group>
				</v-list>

				<v-list flat tile subheader light class="pa-0">
					<v-list-group :value="false" no-action class="font-weight-bold" :ripple="false" color="grey darken-4">
						<template v-slot:activator>
							<v-list-item-title class="filter-caption"> Renk </v-list-item-title>
						</template>
						<v-list class="py-0 font-weight-medium">
							<v-list-item class="px-5">
								<v-list-item-content>
									<v-item-group multiple v-model="filterOptions.colors" style="width: 100%">
										<v-row>
											<v-col
												v-for="(color, clrIndex) in colors"
												:key="`color-${clrIndex}`"
												cols="3"
												class="d-flex justify-center"
											>
												<v-item v-slot="{ active, toggle }" :value="color.id">
													<v-btn
														fab
														:dark="color.value.toLowerCase() !== 'beyaz'"
														depressed
														class="color-btn d-flex align-center"
														height="24"
														width="24"
														:color="colorTransformation(color.value)"
														@click="colorToggle(toggle)"
													>
														<v-icon size="5" v-if="active">fas fa-circle</v-icon>
													</v-btn>
												</v-item>
											</v-col>
										</v-row>
									</v-item-group>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-list-group>
				</v-list>
			</div>
		</div>
	</div>
</template>

<script>
import histogramSlider from '@/components/listing/histogramSlider.vue';
import { CLEAR_FILTER } from '@/store/modules/listing.module';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'FilterSide',
	components: {
		histogramSlider,
	},
	data() {
		return {
			yearRange: [],
			priceRange: [],
			kmRange: [],
			opportunity: false,
			carListContainerHeight: 1080,
			urlFiltered: false,
		};
	},
	computed: {
		...mapGetters(['filterPanels', 'filterPreset', 'filterPayload', 'showFilterCount', 'isFetching']),
		style() {
			return {
				'--v-filter-max-height': `${this.filterContainerHeight}px`,
			};
		},
		isUrlFiltered() {
			return this.filterOptions && this.brands.length > 0 && !this.urlFiltered;
		},
		filterContainerHeight() {
			return this.carListContainerHeight;
		},
		filterOptions() {
			return this.filterPayload;
		},
		prices() {
			return this.filterPreset ? this.filterPreset.prices : [];
		},
		brands() {
			return this.filterPreset ? this.filterPreset.brands : [];
		},
		years() {
			return this.filterPreset ? this.filterPreset.years : [];
		},
		kilometers() {
			return this.filterPreset ? this.filterPreset.kilometers : [];
		},
		categories() {
			return this.filterPreset ? this.filterPreset.categories : [];
		},
		bodyTypes() {
			return this.filterPreset ? this.filterPreset.bodyTypes : [];
		},
		transmissionTypes() {
			return this.filterPreset ? this.filterPreset.transmissionTypes : [];
		},
		tractionTypes() {
			return this.filterPreset ? this.filterPreset.tractionTypes : [];
		},
		versions() {
			return this.filterPreset ? this.filterPreset.versions : [];
		},
		fuelTypes() {
			return this.filterPreset ? this.filterPreset.fuelTypes : [];
		},
		colors() {
			return this.filterPreset ? this.filterPreset.colors : [];
		},
		opportunits() {
			return this.filterPreset ? this.filterPreset.opportunits : [];
		},
		anyCountGreaterThanZero() {
			return this.opportunits.some((item) => item.count > 0);
		},
	},
	created() {
		this.$nextTick(() => {
			if (this.filterOptions?.brands?.length > 0 && this.filterOptions?.models?.length === 0) {
				const checkBranch = this.filterPreset?.brands.find((x) => x.id === this.filterOptions.brands[0]);
				this.toggleBrand(checkBranch);
			}
		});
	},
	methods: {
		...mapActions({
			clearfilter: CLEAR_FILTER,
		}),
		resetFilter() {
			this.yearRange = [];
			this.priceRange = [];
			this.kmRange = [];
			this.opportunity = false;
			this.$refs.yearHistogram.reset();
			this.$refs.priceHistogram.reset();
			this.$refs.kmHistogram.reset();

			this.$router.push({ path: '/araclar' }).catch((err) => {
				if (err.name !== 'NavigationDuplicated') {
					//
				}
			});

			this.clearfilter();
		},
		colorTransformation(color) {
			switch (color.toLowerCase()) {
				case 'bej':
					return '#f5f5dc';
				case 'beyaz':
					return '#ffffff';
				case 'bordo':
					return '#4C0013';
				case 'füme':
					return '#ffc0cb';
				case 'gri':
					return '#808080';
				case 'gümüşgri':
					return '#c0c0c0';
				case 'kahverengi':
					return '#38250E';
				case 'kırmızı':
					return '#cf1827';
				case 'lacivert':
					return '120a8f';
				case 'mavi':
					return '32527b';
				case 'metalikgri':
					return '#717171';
				case 'mor':
					return '#30106b';
				case 'pembe':
					return '#f699cd';
				case 'şampanya':
					return '#f7e7ce';
				case 'sarı':
					return '#fdcc0d';
				case 'siyah':
					return '#000000';
				case 'turkuaz':
					return '#40e0d0';
				case 'turuncu':
					return '#da680f';
				case 'yeşil':
					return '#296e01';
				default:
					return null;
			}
		},
		initializeFiltersFromURL() {
			const brandSlugs = this.$route.params.brands ? this.$route.params.brands.split('-') : [];
			const modelSlugs = this.$route.params.models ? this.$route.params.models.split('-') : [];

			if (brandSlugs.length > 0) {
				const selectedBrandIds = brandSlugs
					.map((brandSlug) => {
						const brand = this.brands.find((b) => this.$toSlug(b.value, '_') === brandSlug);
						return brand ? brand.id : null;
					})
					.filter((id) => id !== null);

				this.filterOptions.brands = selectedBrandIds;
			}

			if (modelSlugs.length > 0) {
				const selectedModelIds = [];

				this.filterOptions.brands.forEach((brandId) => {
					const brand = this.brands.find((b) => b.id === brandId);
					if (brand) {
						const models = brand.models
							.filter((model) => modelSlugs.includes(this.$toSlug(model.value, '_')))
							.map((model) => model.id);
						selectedModelIds.push(...models);
					}
				});

				this.filterOptions.models = selectedModelIds;
			}
		},
		updateURLWithBrand() {
			let path = '/araclar';
			let brandsPath = '';
			let modelsPath = '';

			if (this.filterOptions.brands.length > 0) {
				const selectedBrands = this.filterOptions.brands
					.map((brandId) => {
						const brand = this.filterPreset.brands.find((b) => b.id === brandId);
						return brand ? this.$toSlug(brand.value, '_') : null;
					})
					.filter((brand) => brand !== null);

				if (selectedBrands.length > 0) {
					brandsPath = `${selectedBrands.join('-')}`;
				}
			}

			if (this.filterOptions.models.length > 0) {
				const selectedModels = [];

				this.filterOptions.brands.forEach((brandId) => {
					const brand = this.filterPreset.brands.find((b) => b.id === brandId);
					if (brand) {
						const models = brand.models
							.filter((model) => this.filterOptions.models.includes(model.id))
							.map((model) => this.$toSlug(model.value, '_'));
						selectedModels.push(...models);
					}
				});

				if (selectedModels.length > 0) {
					modelsPath = `${selectedModels.join('-')}`;
				}
			}

			if (brandsPath) {
				path += `/${brandsPath}`;
			}
			if (modelsPath) {
				path += `/${modelsPath}`;
			}

			this.$router.push({ path }).catch((err) => {
				if (err.name !== 'NavigationDuplicated') {
					// console.error(err);
				}
			});
		},
		toggleBrand(brand) {
			this.filterOptions.pageNumber = 1;

			if (brand?.models) {
				const models = brand.models.map((x) => x.id);
				const hasBrand = this.filterOptions.brands.indexOf(brand.id) > -1;

				if (hasBrand) this.filterOptions.models.push(...models);
				else this.filterOptions.models = this.filterOptions.models.filter((x) => !models.includes(x));
			}

			this.updateURLWithBrand();
		},
		toggleModel(brand) {
			this.resetPageNumber();

			if (brand && !this.filterOptions.brands.includes(brand.id)) {
				this.filterOptions.brands.push(brand.id);
			}

			if (this.isOpen(brand)) {
				this.updateBrandCheckState(brand);
			}

			this.updateURLWithBrand();
		},
		updateBrandCheckState(brand) {
			const { models } = brand;
			const selectedModels = models.filter((model) => this.filterOptions.models.includes(model.id));

			if (selectedModels.length === 0) {
				this.filterOptions.brands = this.filterOptions.brands.filter((x) => x !== brand.id);
			}
		},
		isOpen(brand) {
			return this.filterOptions?.brands?.some((x) => x === brand.id);
		},
		colorToggle(toggle) {
			this.resetPageNumber();

			toggle();
		},
		resetPageNumber() {
			this.filterOptions.pageNumber = 1;
		},
	},
	watch: {
		// isUrlFiltered: {
		// 	handler(val) {
		// 		if (val) {
		// 			setTimeout(() => {
		// 				this.initializeFiltersFromURL();
		// 				this.urlFiltered = true;
		// 			}, 500);
		// 		}
		// 	},
		// 	immediate: true,
		// },
		yearRange: {
			handler(value) {
				const [first, second] = value;
				this.filterOptions.startYear = first;
				this.filterOptions.endYear = second;

				this.resetPageNumber();
			},
			deep: true,
		},
		kmRange: {
			handler(value) {
				const [first, second] = value;
				this.filterOptions.startKilometer = first;
				this.filterOptions.endKilometer = second;

				this.resetPageNumber();
			},
			deep: true,
		},
		priceRange: {
			handler(value) {
				const [first, second] = value;
				this.filterOptions.startPrice = first;
				this.filterOptions.endPrice = second;

				this.resetPageNumber();
			},
			deep: true,
		},
		isFetching: {
			handler() {
				if (!this.isFetching) {
					if (this.$vuetify.breakpoint.mdAndUp) {
						this.$nextTick(() => {
							this.carListContainerHeight = this.$parent.$refs.carList.$el?.offsetHeight ?? 1080;
							if (this.carListContainerHeight < window.innerHeight)
								this.carListContainerHeight = window.innerHeight - 90;
						});
					}
				}
			},
		},
	},
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	background: #e4e4e4 !important;
}

::-webkit-scrollbar-thumb {
	background: #cccccc !important;
}

::-webkit-scrollbar-thumb:hover {
	background: rgb(190, 190, 190) !important;
}

.filter-expansion {
	box-shadow: 10px 0px 15px rgba(0, 0, 0, 0.1) !important;
	.filter-side {
		max-height: var(--v-filter-max-height);

		&.mobile {
			max-height: none !important;
		}

		position: relative;
		contain: content;

		.clear-filter {
			position: sticky;
			right: 0px;
			border-bottom: 1px solid #f5f5f5 !important;
		}

		.color-btn {
			box-shadow: 0px 0px 5px 1px rgba(138, 138, 138, 0.2);
		}

		::v-deep {
			.v-list-group {
				&__header {
					border-bottom: 1px solid rgb(236, 236, 236) !important;
					background-color: #f5f5f5;
				}
				.v-list-item {
					min-height: 40px !important;
				}
			}

			.brand-item {
				.v-list-group__header {
					background-color: #ffffff !important;
				}
			}

			.irs-handle {
				top: calc(50% - var(--handle-size) / 2 + 13px) !important;
				background-color: var(--v-template-color-darken1);
			}

			.irs-bar {
				background-color: var(--v-template-color-base);
			}

			.range {
				input {
					color: #a0a0a0 !important;
					text-align: right;
				}
			}

			.range--label {
				color: #cacaca;
				margin-top: 6px;
				font-size: 12px;
				font-weight: bold;
			}
		}
	}
}
</style>
